.gague-chart-container {
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 40px;
  border-radius: 5px;
  height: 200px;
}
.gague-chart-container .gauge-chart-header {
  width: 100%;
  display: flex;
  position: relative;
}
.gague-chart-container .gauge-chart-header .gauge-chart-title {
  font-weight: 700;
}