.approved-cell {
  color: #037847;
  background-color: #ECFDF3;
}
.approved-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #14BA6D;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.nominated-cell {
  color: #C59900;
  background-color: #FFF7DA;
}
.nominated-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFC90C;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.completed-cell {
  color: #0066CC;
  background-color: #E6F7FF;
}
.completed-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #004080;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.withdrawn-cell {
  color: #FF8C00;
  background-color: #FFF3E6;
}
.withdrawn-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #F2784B;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.removed-cell {
  color: #990000;
  background-color: #FEEEEE;
}
.removed-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #800000;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.declined-cell {
  color: #800080;
  background-color: #F4E8FF;
}
.declined-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4B0082;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.ready-cell {
  color: #990000;
  background-color: #FEEEEE;
}
.ready-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #800000;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.in-progress-cell {
  color: #C59900;
  background-color: #FFF7DA;
}
.in-progress-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFC90C;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.reviewed-cell {
  color: #037847;
  background-color: #ECFDF3;
}
.reviewed-cell::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #14BA6D;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.flexible-table-container {
  overflow-x: auto;
  max-width: 100%;
  margin: 5px 0;
  position: relative;
}
.flexible-table-container * {
  font-family: "Inter", sans-serif;
}

.flexible-table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
}
.flexible-table thead {
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
}
.flexible-table th, .flexible-table td {
  padding: 12px 24px;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid #EAECF0;
  color: #667085;
}
.flexible-table th {
  font-weight: 500;
  font-size: 12px;
}
.flexible-table td {
  background-color: #fff;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}
.flexible-table td .status-cell {
  border-radius: 24px;
  padding: 4px 8px 4px 20px;
  position: relative;
}
.show-more-btn {
  font-size: .9em;
  text-decoration: underline;
  color:#e79e00
}